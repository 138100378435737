import ky from "ky";
import { KyInstance } from "ky/distribution/types/ky";

import { normalize } from "../../utils/normalize";
import { env } from "../../utils/environment";

interface Options {
  authToken: string;
}

const travifyHost = () => {
  switch (env) {
    case "production":
      return "https://api.travify.dk";
    case "staging":
      return "https://apitest.travify.dk";
    case "test":
      return "http://test.travify.local";
    case "development":
      return "http://localhost:3000";
    default:
      throw new Error("Unsupported environment");
  }
};

const travifyBaseUrl: string = `${travifyHost()}/api/v5`;

export const baseInstance: KyInstance = ky.create({
  timeout: 60 * 1000,
  parseJson: (text) => normalize(JSON.parse(text)),
});

export const createTravifyApiInstance = ({ authToken }: Options) =>
  baseInstance.extend({
    prefixUrl: travifyBaseUrl,
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
