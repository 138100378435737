import { useContext } from "react";
import { KyInstance } from "ky/distribution/types/ky";

import WidgetContext from "../providers/WidgetContext/WidgetContext";

const useApi = (): KyInstance => {
  const { apiInstance } = useContext(WidgetContext);

  if (!apiInstance) {
    throw new Error("Undefined api instance");
  }

  return apiInstance;
};

export default useApi;
