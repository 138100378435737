import { KyInstance } from "ky/distribution/types/ky";

import { ArrivalDate } from "../../types";

const fetchArrivalDates = async (
  apiInstance: KyInstance,
  subseason: string | null,
): Promise<ArrivalDate[]> => {
  const searchParams = new URLSearchParams();
  if (subseason) searchParams.set("subseason", subseason);

  const response = await apiInstance.get("arrival_dates", {
    searchParams,
  });
  if (response.status === 204) return [];

  return response.json<ArrivalDate[]>();
};

export default fetchArrivalDates;
