import { HotelData } from "./BaseHotelData";
import { RoomDetail } from "./RoomDetail";

interface Extras {
  readonly antal: number;
  readonly betegnelse: string;
  readonly idRoom: number;
  readonly idYdelseantal: number;
  readonly idYdelsetype: number;
  readonly tekst: string;
}

interface RoomExternalId {
  readonly roomId: number;
  readonly externalId: string;
}

export interface MinimumPrice extends HotelData {
  readonly ageString: string;
  readonly subseason: string;
  readonly hotelCategory: number;
  readonly combinationAvailable: number;
  readonly combinationFree: number;
  readonly departureDate: Date;
  readonly destinationCode: string;
  readonly destinationId: number;
  readonly discountPrice: string;
  readonly displayDays: number;
  readonly favouriteHotel: boolean;
  readonly includedExtras: Extras[];
  readonly currency: string;
  readonly lastMinute: boolean;
  readonly minimumPrice: string;
  readonly offer: boolean;
  readonly ownHotel: boolean;
  readonly periodId: number;
  readonly returnDate: Date;
  readonly roomDescription: string;
  readonly roomExternalIds: RoomExternalId[];
  readonly roomOnRequest: number;
  readonly rooms: RoomDetail[];
  readonly sort: number | null;
  readonly transportAvailable: number;
  readonly transportDisplayDays: number;
  readonly transportFree: number;
  readonly travelLength: number;
  readonly weekno?: number;
}
