import React from "react";
import styled from "styled-components";
import { cssValue } from "../../helpers/unitConverter";

interface SpinnerProps {
  color?: string;
  size?: number | string;
  speedMultiplier?: number;
}

interface InnerProps {
  color: string;
  size: number | string;
  speedMultiplier: number;
}

const StyledSpinner = styled.span<InnerProps>`
  background: transparent !important;
  width: ${({ size }) => cssValue(size)};
  height: ${({ size }) => cssValue(size)};
  border-radius: 100%;
  border: 2px solid;
  border-color: ${({ color }) => color} ${({ color }) => color} transparent;
  border-image: initial;
  display: inline-block;
  animation: rotate ${({ speedMultiplier }) => 0.75 / speedMultiplier}s 0s
    infinite linear;

  @keyframes rotate {
    0% {
      transform: rotate(0deg) scale(1);
    }
    50% {
      transform: rotate(180deg) scale(0.8);
    }
    100% {
      transform: rotate(360deg) scale(1);
    }
  }
`;

const Spinner: React.FC<SpinnerProps> = ({
  color = "#000000",
  size = 35,
  speedMultiplier = 1,
}) => (
  <StyledSpinner color={color} size={size} speedMultiplier={speedMultiplier} />
);

export default Spinner;
