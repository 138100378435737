export type Heading = {
  description: string | null;
  key: string;
};

export type ListValue<Value extends ListableValueType = string> = {
  value: Value;
  description: string;
};

export interface Listable<Value extends ListableValueType = string> {
  values: Map<string, ListValue<Value>[]>;
  headings: Map<string, Heading>;
}

export type ListableValueType = string | number;
