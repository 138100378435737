import { KyInstance } from "ky/distribution/types/ky";

import {
  Area,
  Destination,
  ArrivalDate,
  TransportGroupWithNameKeyAndDescription,
} from "../../types";
import wrapPromise, { Wrap } from "../../utils/wrapPromise";
import fetchAreas from "./fetchAreas";
import fetchDestinations from "./fetchDestinations";
import fetchArrivalDates from "./fetchArrivalDates";
import fetchTransports from "./fetchTransports";
import entries from "../../utils/entries";
import { parseString } from "../../utils/parseString";

export interface SearchMasterData {
  areasData: Wrap<Area[]>;
  destinationsData: Wrap<Destination[]>;
  arrivalDatesData: Wrap<ArrivalDate[]>;
  transportsData: Wrap<TransportGroupWithNameKeyAndDescription[]>;
}

interface SessionStorageSearchMasterData {
  areasData: Area[];
  destinationsData: Destination[];
  arrivalDatesData: ArrivalDate[];
  transportsData: TransportGroupWithNameKeyAndDescription[];
}

const sessionStorageKey = (subseason: string | null) =>
  subseason ? `masterData-${subseason}` : "masterData";

const persistedMasterData = (
  subseason: string | null,
): Partial<SessionStorageSearchMasterData> | null =>
  (sessionStorage.getItem(sessionStorageKey(subseason)) &&
    JSON.parse(sessionStorage.getItem(sessionStorageKey(subseason)) ?? "{}")) ??
  null;

const wrappedPersistedMasterData = (
  subseason: string | null,
): Partial<SearchMasterData> | null => {
  const masterData = persistedMasterData(subseason);

  return masterData
    ? entries(masterData).reduce<Partial<SearchMasterData>>((acc, curr) => {
        if (curr) {
          const [key, value] = curr;
          if (value) {
            switch (key) {
              case "areasData":
                acc[key] = {
                  read: () => value,
                };
                break;
              case "destinationsData":
                acc[key] = {
                  read: () => value,
                };
                break;
              case "arrivalDatesData":
                acc[key] = {
                  read: () =>
                    value.map((arrivalDate) => ({
                      ...arrivalDate,
                      transportGroupDepartures: [
                        ...arrivalDate.transportGroupDepartures.map(
                          (transportGroupDeparture) => ({
                            ...transportGroupDeparture,
                            departureDate: parseString(
                              transportGroupDeparture.departureDate as unknown as string,
                            ) as Date,
                          }),
                        ),
                      ],
                      date: parseString(
                        arrivalDate.date as unknown as string,
                      ) as Date,
                    })),
                };
                break;
              case "transportsData":
                acc[key] = {
                  read: () => value,
                };
                break;
              default:
                throw new Error("Unsupported masterdata key");
            }
          }
        }

        return acc;
      }, {})
    : null;
};

export const persistMasterData = (
  data: Partial<SessionStorageSearchMasterData>,
  subseason: string | null,
) => {
  const persistedData: Partial<SessionStorageSearchMasterData> = JSON.parse(
    sessionStorage.getItem(sessionStorageKey(subseason)) ?? "{}",
  );

  sessionStorage.setItem(
    sessionStorageKey(subseason),
    JSON.stringify({ ...persistedData, ...data }),
  );
};

const fetchSearchMasterData = (
  apiInstance: KyInstance,
  subseason: string | null,
): SearchMasterData => {
  const masterData = wrappedPersistedMasterData(subseason);

  const data: SearchMasterData = {
    areasData:
      masterData?.areasData ??
      wrapPromise(
        fetchAreas(apiInstance, subseason).then((response) => {
          persistMasterData({ areasData: response }, subseason);

          return response;
        }),
      ),
    destinationsData:
      masterData?.destinationsData ??
      wrapPromise(
        fetchDestinations(apiInstance, subseason).then((response) => {
          persistMasterData({ destinationsData: response }, subseason);

          return response;
        }),
      ),
    arrivalDatesData:
      masterData?.arrivalDatesData ??
      wrapPromise(
        fetchArrivalDates(apiInstance, subseason).then((response) => {
          persistMasterData({ arrivalDatesData: response }, subseason);

          return response;
        }),
      ),
    transportsData:
      masterData?.transportsData ??
      wrapPromise(
        fetchTransports(apiInstance, subseason).then((response) => {
          persistMasterData({ transportsData: response }, subseason);

          return response;
        }),
      ),
  };

  return data;
};

export default fetchSearchMasterData;
