import React from "react";
import { Notifier } from "@airbrake/browser";

import airbrake, { reportError } from "../../utils/airbrake";

interface Props {
  fallback: React.ReactNode;
  children?: React.ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  private airbrake: Notifier;

  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
    };
    this.airbrake = airbrake;
  }

  static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    if (reportError) {
      // Send error to Airbrake
      try {
        this.airbrake.notify({
          error,
          params: { info },
        });
      } catch (err) {
        console.log(err);
      }
    }
  }

  render() {
    const { fallback, children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      // You can render any custom fallback UI
      return fallback;
    }

    return children;
  }
}

export default ErrorBoundary;
