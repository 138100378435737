import { createContext } from "react";

import { WidgetContextType } from "./types";

const ApiContext = createContext<WidgetContextType>({
  apiInstance: null,
  subseason: null,
  preselectedValues: {},
});

export default ApiContext;
