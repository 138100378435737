import { Notifier } from "@airbrake/browser";

import { env } from "./environment";

export const reportError: boolean =
  ["production", "staging"].includes(env) &&
  !window.location.search.includes("debug");

let travifySearchHosts: string[];
const isBot = /bot|googlebot|crawler|spider|robot|crawling/i.test(
  navigator.userAgent,
);

switch (env) {
  case "production":
    travifySearchHosts = [
      "https://thinggaard.dk/wp-content/plugins/travify-search",
    ];
    break;
  case "staging":
    travifySearchHosts = [
      "https://staging.thinggaard.dk/wp-content/plugins/travify-search",
    ];
    break;
  default:
    travifySearchHosts = [];
    break;
}

const airbrake = new Notifier({
  projectId: 541784,
  projectKey: "11c861ceebb3e08eda19f55e26f63c6f",
  environment: env,
});

airbrake.addFilter((notice) => {
  const files =
    notice.errors?.flatMap((error) =>
      error.backtrace.map((frame) => frame.file),
    ) ?? [];

  const match = files.some((file) =>
    travifySearchHosts.some((host) => file.includes(host)),
  );

  if (!reportError) return null;
  if (!match) return null;
  if (isBot) return null;

  return notice;
});

export default airbrake;
