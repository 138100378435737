import { KyInstance } from "ky/distribution/types/ky";

import { Area } from "../../types";

const fetchAreas = async (
  apiInstance: KyInstance,
  subseason: string | null,
): Promise<Area[]> => {
  const searchParams = new URLSearchParams();
  if (subseason) searchParams.set("subseason", subseason);

  const response = await apiInstance.get("areas", {
    searchParams,
  });

  if (response.status === 204) return [];

  return response.json<Area[]>();
};

export default fetchAreas;
