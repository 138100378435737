import entries from "./entries";

export interface Breakpoints {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
  xxl: number;
}

export type DeviceType =
  | "mobile"
  | "tablet"
  | "laptop"
  | "desktop"
  | "bigScreen";

type Size = {
  [key in keyof Breakpoints]: string;
};

interface Device {
  max: Size;
  min: Size;
  only: Size;
}

export const breakpoints: Breakpoints = {
  xs: 400, // for small screen mobile
  sm: 600, // for mobile screen
  md: 900, // for tablets
  lg: 1280, // for laptops
  xl: 1440, // for desktop / monitors
  xxl: 1920, // for big screens
};

export const getCurrentSize = (width: number): keyof Breakpoints => {
  if (width <= breakpoints.xs) return "xs";
  if (width <= breakpoints.sm) return "sm";
  if (width <= breakpoints.md) return "md";
  if (width <= breakpoints.lg) return "lg";
  if (width <= breakpoints.xl) return "xl";

  return "xxl";
};

export const getDeviceTypeByBreakpoint = (
  breakpoint: keyof Breakpoints,
): DeviceType => {
  if (["xs", "sm"].includes(breakpoint)) return "mobile";
  if (breakpoint === "md") return "tablet";
  if (breakpoint === "lg") return "laptop";
  if (breakpoint === "xl") return "desktop";

  return "bigScreen";
};

export const getDeviceTypeByWidth = (width: number): DeviceType => {
  const breakpoint = getCurrentSize(width);

  return getDeviceTypeByBreakpoint(breakpoint);
};

const formatSize = (size: number): string => `${size}px`;

const size: Size = entries(breakpoints).reduce<Partial<Size>>(
  (acc, [key, value]) => {
    acc[key] = formatSize(value);

    return acc;
  },
  {},
) as Size;

export const device: Device = {
  max: {
    xs: `(max-width: ${size.xs})`,
    sm: `(max-width: ${size.sm})`,
    md: `(max-width: ${size.md})`,
    lg: `(max-width: ${size.lg})`,
    xl: `(max-width: ${size.xl})`,
    xxl: `(max-width: ${size.xxl})`,
  },
  min: {
    xs: `(max-width: ${size.xs})`,
    sm: `(min-width: calc(${size.xs} + 1px))`,
    md: `(min-width: calc(${size.sm} + 1px)`,
    lg: `(min-width: calc(${size.md} + 1px))`,
    xl: `(min-width: calc(${size.lg} + 1px))`,
    xxl: `(min-width: calc(${size.xl} + 1px))`,
  },
  only: {
    xs: `(max-width: ${size.xs})`,
    sm: `(max-width: ${size.sm}) and (min-width: calc(${size.xs} + 1px))`,
    md: `(max-width: ${size.md}) and (min-width: calc(${size.sm} + 1px))`,
    lg: `(max-width: ${size.lg}) and (min-width: calc(${size.md} + 1px))`,
    xl: `(max-width: ${size.xl}) and (min-width: calc(${size.lg} + 1px))`,
    xxl: `(min-width: calc(${size.xl} + 1px))`,
  },
};
