import { useContext } from "react";

import WidgetContext from "../providers/WidgetContext/WidgetContext";

const useSubseason = (): string | null => {
  const { subseason } = useContext(WidgetContext);

  return subseason;
};

export default useSubseason;
