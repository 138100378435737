import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import WordpressHotelDataContext from "./WordpressHotelDataContext";
import WidgetContext from "../WidgetContext/WidgetContext";
import {
  HotelData,
  HotelMeta,
  WordpressHotelData,
  WordpressHotelDataContextType,
} from "./types";
import { baseInstance } from "../../service/api/instance";
import usePrevious from "../../hooks/usePrevious";

interface Props {
  children: React.ReactNode;
}

const renderDistance = (distanceString: string): string => {
  if (distanceString === "-") return distanceString;

  return `${distanceString} m`;
};

function decode(str: string): string {
  const txt = document.createElement("textarea");

  txt.innerHTML = str;

  return txt.value;
}

const sessionStorageKey = "wordpressHotelData";

const hotelDataKey = (hotelCode: string, subseason: string) =>
  `${hotelCode}-${subseason}`;

const WordpressHotelDataProvider: React.FC<Props> = ({ children }) => {
  const { externalHotelEndpoint } = useContext(WidgetContext);
  const [hotelDataMap, setHotelDataMap] = useState<
    Map<string, HotelData | null>
  >(
    new Map<string, HotelData | null>(
      new Map(
        Object.entries(
          JSON.parse(sessionStorage.getItem(sessionStorageKey) ?? "{}"),
        ),
      ),
    ),
  );
  const prevHotelDataMap = usePrevious(hotelDataMap);

  const fetchHotelData = useCallback(
    async (hotelCode: string, subseason: string): Promise<HotelData | null> => {
      if (!externalHotelEndpoint) throw new Error("No external hotel endpoint");

      const mapKey = hotelDataKey(hotelCode, subseason);
      const { result } = await baseInstance
        .get(
          externalHotelEndpoint
            .replace("{hotelCode}", hotelCode)
            .replace("{subseason}", subseason),
        )
        .json<WordpressHotelData>();

      let hotelData: HotelData | null = null;

      if (result) {
        const { meta } = result;
        const extras: HotelMeta[] = [];

        if (
          meta.hotelMetaDistanceCenter &&
          meta.hotelMetaDistanceCenter !== ""
        ) {
          extras.push({
            label: "Afstand til Centrum",
            value: renderDistance(meta.hotelMetaDistanceCenter),
          });
        }
        if (meta.hotelMetaDistanceLift && meta.hotelMetaDistanceLift !== "") {
          extras.push({
            label: "Afstand til Lift",
            value: renderDistance(meta.hotelMetaDistanceLift),
          });
        }
        if (
          meta.hotelMetaDistanceSkibus &&
          meta.hotelMetaDistanceSkibus !== ""
        ) {
          extras.push({
            label: "Afstand til skibus",
            value: renderDistance(meta.hotelMetaDistanceSkibus),
          });
        }
        if (meta.hotelMetaInternet && meta.hotelMetaInternet !== "") {
          extras.push({
            label: "Internet",
            value: meta.hotelMetaInternet,
          });
        }
        if (meta.hotelMetaPension) {
          extras.push({
            label: "Pension",
            value: meta.hotelMetaPension.label,
          });
        }

        hotelData = {
          images: [result.images.large[0]],
          description: result.snippet,
          usps: {
            extras,
            inclusive: meta.hotelMetaOptions
              ? meta.hotelMetaOptions.map((option) => option.label)
              : [],
          },
          hotelUrl: decode(result.guid),
        };
      }

      setHotelDataMap((map) => {
        const newMap = new Map(map);
        newMap.set(mapKey, hotelData);

        return newMap;
      });

      return hotelData;
    },
    [externalHotelEndpoint],
  );

  useEffect(() => {
    if (hotelDataMap !== prevHotelDataMap) {
      sessionStorage.setItem(
        sessionStorageKey,
        JSON.stringify(Object.fromEntries(hotelDataMap)),
      );
    }
  });

  const getHotelData = useCallback(
    async (
      hotelCode: string,
      subseason: string,
    ): Promise<HotelData | null | undefined> => {
      const mapKey = hotelDataKey(hotelCode, subseason);

      if (hotelDataMap.has(mapKey)) return hotelDataMap.get(mapKey);

      return fetchHotelData(hotelCode, subseason);
    },
    [fetchHotelData, hotelDataMap],
  );

  const context = useMemo<WordpressHotelDataContextType>(
    () => ({
      hotelDataMap,
      getHotelData,
    }),
    [getHotelData, hotelDataMap],
  );

  return (
    <WordpressHotelDataContext.Provider value={context}>
      {children}
    </WordpressHotelDataContext.Provider>
  );
};

export default WordpressHotelDataProvider;
