import React, {
  PropsWithChildren,
  createContext,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { BaseDeviceTypeContext } from "./types";
import {
  Breakpoints,
  DeviceType,
  getCurrentSize,
  getDeviceTypeByWidth,
} from "../../utils/breakpoints";

export const Context = createContext<BaseDeviceTypeContext | null>(null);

const Provider: React.FC<PropsWithChildren> = ({ children }) => {
  const [[width, height], setSize] = useState<
    [width: number | null, height: number | null]
  >([null, null]);

  const currentSize = useMemo<keyof Breakpoints | null>(
    () => (width ? getCurrentSize(width) : null),
    [width],
  );
  const deviceType = useMemo<DeviceType | null>(
    () => (width ? getDeviceTypeByWidth(width) : null),
    [width],
  );
  const context = useMemo<BaseDeviceTypeContext | null>(() => {
    return {
      currentSize,
      deviceType,
      width,
      height,
    };
  }, [currentSize, deviceType, height, width]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();

    return () => window.removeEventListener("resize", updateSize);
  }, []);

  if (!context?.currentSize || !context?.deviceType) {
    return null;
  }

  return <Context.Provider value={context}>{children}</Context.Provider>;
};

export default Provider;
