import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { ThemeProvider, StyleSheetManager } from "styled-components";
import { getContrast } from "polished";
import { IntlProvider } from "react-intl";

import App from "./App";
import Loading from "./components/atoms/Loading";
import { Theme } from "./types";
import ErrorBoundary from "./components/organisms/ErrorBoundary";
import { PreselectedValues } from "./types/PreselectedValues";
import { env } from "./utils/environment";

// Find all widget divs
const WidgetDivs: NodeListOf<HTMLDivElement> = document.querySelectorAll(
  "div.travify-search-widget",
);

const lightColor: string = "white";
const darkColor: string = "black";

const getTextColor = (background: string): string => {
  const lightContrast = getContrast(background, lightColor);
  const darkContrast = getContrast(background, darkColor);

  return lightContrast >= darkContrast ? lightColor : darkColor;
};

const primaryColorDefault = "grey";
const primaryColorLightDefault = "white";
const primaryColorLighterDefault = "lightgrey";
const secondaryColorDefault = "#f37022";
const secondaryColorLightDefault = "#f37022";
const secondaryColorLighterDefault = "#f37022";
const warningColorDefault = "#e39727";
const warningColorLightDefault = "#eba845";
const warningColorLighterDefault = "#eba845bd";

const disabledColor = "#ccc";

const defaultTheme: Theme = {
  colors: {
    primary: {
      background: primaryColorDefault,
      color: getTextColor(primaryColorDefault),
      light: {
        background: primaryColorLightDefault,
        color: getTextColor(primaryColorLightDefault),
      },
      lighter: {
        background: primaryColorLighterDefault,
        color: getTextColor(primaryColorLighterDefault),
      },
    },
    secondary: {
      background: secondaryColorDefault,
      color: "white",
      light: {
        background: secondaryColorLightDefault,
        color: getTextColor(secondaryColorLightDefault),
      },
      lighter: {
        background: secondaryColorLighterDefault,
        color: getTextColor(secondaryColorLighterDefault),
      },
    },
    disabled: {
      background: disabledColor,
      color: getTextColor(disabledColor),
      light: {
        background: disabledColor,
        color: getTextColor(disabledColor),
      },
      lighter: {
        background: disabledColor,
        color: getTextColor(disabledColor),
      },
    },
    warning: {
      background: warningColorDefault,
      color: getTextColor(warningColorDefault),
      light: {
        background: warningColorLightDefault,
        color: getTextColor(warningColorLightDefault),
      },
      lighter: {
        background: warningColorLighterDefault,
        color: getTextColor(warningColorLighterDefault),
      },
    },
  },
};

WidgetDivs.forEach((Div) => {
  const shadowRoot = Div.attachShadow({ mode: "open" });
  const styleSection = document.createElement("section");
  shadowRoot.appendChild(styleSection);
  const root = createRoot(shadowRoot);
  const {
    dataset: {
      authEndpoint,
      prefix,
      themeMain,
      subseason,
      themeLightMain,
      themeLighterMain,
      preselectedDestinations,
      externalHotelEndpoint,
    },
  } = Div;
  const preselectedValues: PreselectedValues = {
    destinations: preselectedDestinations?.split(","),
  };
  let target = Div.dataset.target ?? "searchbar";

  if (env === "development") {
    target =
      new URLSearchParams(window.location.search).get("target") ?? target;
  }

  if (target !== "searchbar" && target !== "combinations") {
    throw new Error(
      "Invalid widget target, valid options are: 'searchbar', 'combinations'",
    );
  }

  if (!authEndpoint) throw new Error("Missing authentication endpoint");

  const theme: Theme = {
    ...defaultTheme,
  };

  if (themeMain) {
    const primaryBackground = themeMain;
    const primaryBackgroundLight = themeLightMain ?? primaryColorLightDefault;
    const primaryBackgroundLighter =
      themeLighterMain ?? primaryColorLighterDefault;

    theme.colors.primary = {
      background: primaryBackground,
      color: getTextColor(primaryBackground),
      light: {
        background: primaryBackgroundLight,
        color: getTextColor(primaryBackgroundLight),
      },
      lighter: {
        background: primaryBackgroundLighter,
        color: getTextColor(primaryBackgroundLighter),
      },
    };
  }

  root.render(
    <ErrorBoundary fallback={<div>An error occured</div>}>
      <IntlProvider locale="da" messages={{}}>
        <ThemeProvider theme={theme}>
          <Suspense fallback={<Loading />}>
            <StyleSheetManager target={styleSection}>
              <App
                authEndpoint={authEndpoint}
                target={target}
                subseason={subseason ?? null}
                preselectedValues={preselectedValues}
                parameterPrefix={prefix}
                externalHotelEndpoint={externalHotelEndpoint}
              />
            </StyleSheetManager>
          </Suspense>
        </ThemeProvider>
      </IntlProvider>
    </ErrorBoundary>,
  );
});
