export interface Color {
  background: string;
  color: string;
  light: Omit<Color, "light" | "lighter">;
  lighter: Omit<Color, "light" | "lighter">;
}

export interface Theme {
  colors: {
    primary: Color;
    secondary: Color;
    disabled: Color;
    warning: Color;
  };
}
