import { KyInstance } from "ky/distribution/types/ky";

import {
  TransportType,
  TransportGroupWithNameKeyAndDescription,
  TransportGroupWithName,
} from "../../types";
import {
  transportKey,
  transportDescription,
} from "../../utils/transportFunctions";

interface BaseApiTransport {
  transportGroupId: TransportType;
  name: string;
}

interface CarApiTransport extends BaseApiTransport {
  transportGroupId: TransportType.CAR;
}

interface BusApiTransport extends BaseApiTransport {
  transportGroupId: TransportType.BUS;
}

interface PlaneApiTransport extends BaseApiTransport {
  transportGroupId: TransportType.PLANE;
  extraParameter: string;
  extraParameterValues: string[];
}

interface TrainApiTransport extends BaseApiTransport {
  transportGroupId: TransportType.TRAIN;
}

type ApiTransport =
  | CarApiTransport
  | BusApiTransport
  | PlaneApiTransport
  | TrainApiTransport;

const normalizeTransport = (
  apiTransport: ApiTransport,
):
  | TransportGroupWithNameKeyAndDescription
  | TransportGroupWithNameKeyAndDescription[] => {
  switch (apiTransport.transportGroupId) {
    case TransportType.CAR:
    case TransportType.BUS:
    case TransportType.TRAIN: {
      const transport: TransportGroupWithName = {
        name: apiTransport.name,
        transportGroupId: apiTransport.transportGroupId,
      };
      return {
        ...transport,
        key: transportKey(transport),
        description: transportDescription(transport),
      };
    }
    case TransportType.PLANE: {
      return apiTransport.extraParameterValues.map((departureAirportCode) => {
        const transport: TransportGroupWithName = {
          name: apiTransport.name,
          transportGroupId: apiTransport.transportGroupId,
          departureAirportCode,
        };

        return {
          ...transport,
          key: transportKey(transport),
          description: transportDescription(transport),
        };
      });
    }
    default:
      throw new Error("Unsupported transport type");
  }
};

const fetchTransports = async (
  apiInstance: KyInstance,
  subseason: string | null,
): Promise<TransportGroupWithNameKeyAndDescription[]> => {
  const searchParams = new URLSearchParams();

  if (subseason) searchParams.set("subseason", subseason);

  const response = await apiInstance.get("transports", {
    searchParams,
  });
  if (response.status === 204) return [];

  return (await response.json<ApiTransport[]>()).flatMap(normalizeTransport);
};

export default fetchTransports;
