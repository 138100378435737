import { createContext } from "react";
import { MasterDataContext } from "./types";

const SearchMasterDataContext = createContext<MasterDataContext>({
  areas: [],
  destinations: [],
  arrivalDates: [],
  transports: [],
  travelLengths: [],
});

export default SearchMasterDataContext;
