import React from "react";
import { useTheme } from "styled-components";

import { Theme } from "../../types";
import Spinner from "./Spinner";

interface Props {
  size?: number;
  color?: string;
}

const Loading: React.FC<Props> = ({ size = 2, color }) => {
  const {
    colors: {
      primary: { background },
    },
  } = useTheme() as Theme;

  return <Spinner color={color ?? background} size={`${size}rem`} />;
};

export default Loading;
