export enum TransportType {
  CAR = 0,
  BUS = 1,
  PLANE = 2,
  TRAIN = 3,
}

export interface BaseTransportGroup {
  transportGroupId: TransportType;
}

export interface CarTransportGroup extends BaseTransportGroup {
  transportGroupId: TransportType.CAR;
}

export interface BusTransportGroup extends BaseTransportGroup {
  transportGroupId: TransportType.BUS;
}

export interface PlaneTransportGroup extends BaseTransportGroup {
  transportGroupId: TransportType.PLANE;
  departureAirportCode: string;
}

export interface TrainTransportGroup extends BaseTransportGroup {
  transportGroupId: TransportType.TRAIN;
}

export type TransportGroup =
  | CarTransportGroup
  | BusTransportGroup
  | PlaneTransportGroup
  | TrainTransportGroup;

export type TransportGroupWithName = TransportGroup & {
  name: string;
};

export type TransportGroupWithDescription = TransportGroup & {
  description: string;
};

export type TransportGroupWithNameKeyAndDescription = TransportGroupWithName &
  TransportGroupWithDescription & {
    key: string;
  };
