import React, { useMemo } from "react";
import { KyInstance } from "ky/distribution/types/ky";

import WidgetContext from "./WidgetContext";
import { PreselectedValues } from "../../types/PreselectedValues";
import { WidgetContextType } from "./types";

interface Props {
  apiInstance: KyInstance;
  prefix?: string | null;
  subseason: string | null;
  preselectedValues: PreselectedValues;
  externalHotelEndpoint?: string;
  children: React.ReactNode;
}

const WidgetProvider: React.FC<Props> = ({
  apiInstance,
  prefix,
  preselectedValues,
  subseason,
  externalHotelEndpoint,
  children,
}) => {
  const context = useMemo<WidgetContextType>(
    () => ({
      apiInstance,
      prefix,
      preselectedValues,
      externalHotelEndpoint,
      subseason,
    }),
    [apiInstance, externalHotelEndpoint, prefix, preselectedValues, subseason],
  );

  return (
    <WidgetContext.Provider value={context}>{children}</WidgetContext.Provider>
  );
};

export default WidgetProvider;
