import {
  TransportGroup,
  TransportGroupWithName,
  TransportType,
} from "../types";

const seperator = "-";

export const transportKey = (transport: TransportGroup): string => {
  if (transport.transportGroupId === TransportType.PLANE)
    return `${transport.transportGroupId}${seperator}${transport.departureAirportCode}`;

  return transport.transportGroupId.toString();
};

export const parseTransportKey = (
  transportKeyString: string,
): TransportGroup => {
  const [key, extra] = transportKeyString.split(seperator);
  if (!key) throw new Error("Invalid transport key");

  const transportGroupId = parseInt(key, 10);

  if (transportGroupId === TransportType.PLANE) {
    if (!extra) throw new Error("Missing extra parameter for plane transport");

    return {
      transportGroupId,
      departureAirportCode: extra,
    };
  }

  return {
    transportGroupId,
  };
};

export const transportDescription = (
  transport: TransportGroupWithName,
): string => {
  if (transport.transportGroupId === TransportType.PLANE)
    return `${transport.name} - ${transport.departureAirportCode}`;

  return transport.name;
};
