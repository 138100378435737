import React, { ReactNode, useEffect, useMemo, useRef, useState } from "react";

import SearchMasterDataContext from "./SearchMasterDataContext";
import fetchSearchMasterData, {
  SearchMasterData,
} from "../../service/api/fetchSearchMasterData";
import {
  Area,
  ArrivalDate,
  Destination,
  TransportGroupWithNameKeyAndDescription,
} from "../../types";
import { MasterDataContext } from "./types";
import useApi from "../../hooks/useApi";
import Loading from "../../components/atoms/Loading";
import useSubseason from "../../hooks/useSubseason";

interface Props {
  children: ReactNode;
}

interface InnerProps {
  searchMasterData: SearchMasterData;
  children: ReactNode;
}

const Inner: React.FC<InnerProps> = ({ searchMasterData, children }) => {
  const areas = useMemo<Area[]>(
    () => searchMasterData.areasData?.read() ?? [],
    [searchMasterData.areasData],
  );
  const destinations = useMemo<Destination[]>(
    () => searchMasterData.destinationsData?.read() ?? [],
    [searchMasterData.destinationsData],
  );
  const arrivalDates = useMemo<ArrivalDate[]>(
    () => searchMasterData.arrivalDatesData?.read() ?? [],
    [searchMasterData.arrivalDatesData],
  );
  const transports = useMemo<TransportGroupWithNameKeyAndDescription[]>(
    () => searchMasterData.transportsData?.read() ?? [],
    [searchMasterData.transportsData],
  );
  const travelLengths = useMemo<number[]>(
    () =>
      arrivalDates
        .reduce<number[]>((acc, arrivalDate) => {
          if (!acc.includes(arrivalDate.days)) {
            acc.push(arrivalDate.days);
          }

          return acc;
        }, [])
        .sort(),
    [arrivalDates],
  );

  const context = useMemo<MasterDataContext>(() => {
    return {
      areas,
      destinations,
      arrivalDates,
      transports,
      travelLengths,
    };
  }, [areas, arrivalDates, destinations, transports, travelLengths]);

  return (
    <SearchMasterDataContext.Provider value={context}>
      {children}
    </SearchMasterDataContext.Provider>
  );
};

const SearchMasterDataProvider: React.FC<Props> = ({ children }) => {
  const apiInstance = useApi();
  const subseason = useSubseason();
  const isMounted = useRef<boolean>(false);
  const [searchMasterData, setSearchMasterData] =
    useState<SearchMasterData | null>(null);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;

      setSearchMasterData(fetchSearchMasterData(apiInstance, subseason));
    }

    return () => {
      isMounted.current = false;
    };
  }, [apiInstance, subseason]);

  if (!searchMasterData) return <Loading />;

  return <Inner searchMasterData={searchMasterData}>{children}</Inner>;
};

export default React.memo(SearchMasterDataProvider);
