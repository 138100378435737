import { TransportType } from "./Transport";

export interface TransportGroupDeparture {
  departureDate: Date;
  transportgroupId: TransportType;
  travellength: number;
}

export interface ArrivalDate {
  periodId: number;
  date: Date;
  days: number;
  weekNo: number;
  transportGroupDepartures: TransportGroupDeparture[];
}
