import camelcase from "camelcase";

import { parseString } from "./parseString";

type ValueType =
  | string
  | number
  | null
  | undefined
  | Date
  | { [x: string]: ValueType }
  | Array<ValueType>;

const parseValue = (value: ValueType): ValueType => {
  if (value === undefined) return value;
  if (value === null) return value;
  if (typeof value === "string") return parseString(value);
  if (Array.isArray(value)) return value.map((v) => parseValue(v));
  if (typeof value === "object")
    return Object.entries(value).reduce<{ [x: string]: ValueType }>(
      (acc, [key, v]) => {
        acc[camelcase(key)] = parseValue(v);

        return acc;
      },
      {},
    );

  return value;
};

export const normalize = <T>(obj: ValueType): T => parseValue(obj) as T;
