export interface Room {
  roomId: number;
  roomName: string;
  paxBeds: number;
}

export interface Hotel {
  hotelId: number;
  hotelName: string;
  hotelCode: string;
  hotelRooms: Room[];
}

export interface Destination {
  destinationId: number;
  destinationName: string;
  destinationCode: string;
  destinationHotels: Hotel[];
  destinationAreas?: { areaCode: string }[];
}
