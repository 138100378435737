import { KyInstance } from "ky/distribution/types/ky";

import { Destination } from "../../types";

const fetchDestinations = async (
  apiInstance: KyInstance,
  subseason: string | null,
): Promise<Destination[]> => {
  const searchParams = new URLSearchParams();
  if (subseason) searchParams.set("subseason", subseason);

  const response = await apiInstance.get("destinations", {
    searchParams,
  });
  if (response.status === 204) return [];

  return response.json<Destination[]>();
};

export default fetchDestinations;
