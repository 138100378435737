import React from "react";
import { HotelData, WordpressHotelDataContextType } from "./types";

const WordpressHotelDataContext =
  React.createContext<WordpressHotelDataContextType>({
    hotelDataMap: new Map<string, HotelData>(),
    getHotelData: (_hotelCode: string, _subseason: string) => Promise.reject(),
  });

export default WordpressHotelDataContext;
