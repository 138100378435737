import React, { useEffect, useRef, useState, lazy } from "react";
import { KyInstance } from "ky/distribution/types/ky";
import styled from "styled-components";

import DeviceTypeProvider from "./providers/DeviceTypeContext/Provider";
import WidgetProvider from "./providers/WidgetContext/WidgetProvider";
import SearchMasterDataProvider from "./providers/SearchMasterData/SearchMasterDataProvider";
import WordpressHotelDataProvider from "./providers/WordpressHotelData/WordpressHotelDataProvider";

import { baseInstance, createTravifyApiInstance } from "./service/api/instance";

import Loading from "./components/atoms/Loading";

import { env } from "./utils/environment";
import { sleep } from "./utils/sleep";
import wrapPromise, { Wrap } from "./utils/wrapPromise";
import { Target } from "./types";
import { PreselectedValues } from "./types/PreselectedValues";

interface Props {
  authEndpoint: string;
  target: Target;
  subseason: string | null;
  preselectedValues: PreselectedValues;
  parameterPrefix?: string;
  externalHotelEndpoint?: string;
}

const MinimumPrices = lazy(() => import("./components/views/MinimumPrices"));
const Combinations = lazy(() => import("./components/views/Combinations"));

const Container = styled.div`
  margin: auto;
  max-width: 1200px;
`;

const fetchApiKey = async (endpoint: string): Promise<string> => {
  let authToken: string;

  switch (env) {
    case "production": {
      // case "staging":
      const result = await baseInstance
        .get(endpoint)
        .json<{ result: { authToken: string } }>();

      authToken = result.result.authToken;
      break;
    }
    case "staging":
      await sleep(500);
      authToken =
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhYmFzZV9jcmVkZW50aWFscyI6eyJkYXRhYmFzZV9uYW1lIjoiVFJWMDA0X1RISU5HR0FBUkRfVEVTVCIsImNvbXBhbnlfaWQiOiIyIn0sImV4cCI6MTc5MjE0NTczM30.qP3DGw7I2zjQJ_3CyizIsbmlwH_1A5IEdQsFs0BELy8";
      break;
    case "development":
      await sleep(500);
      authToken =
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhYmFzZV9jcmVkZW50aWFscyI6eyJkYXRhYmFzZV9uYW1lIjoiVFJWMDA0X1RISU5HR0FBUkRfVEVTVCIsImNvbXBhbnlfaWQiOiIyIn0sImV4cCI6MTc5MjE0NTczM30.uKaRP4pg0eH7Ewp-qp1p2DIDp_Ouq9knlcSumCvqeJw";
      break;
    default:
      throw new Error("Unsupported environment");
  }

  return authToken;
};

const ensureApiInstance = async (endpoint: string) => {
  const authToken = await fetchApiKey(endpoint);
  const apiInstance = createTravifyApiInstance({ authToken });

  return apiInstance;
};

const renderContent = (target: Target) => {
  switch (target) {
    case "searchbar":
      return <MinimumPrices />;
    case "combinations":
      return <Combinations />;
    default:
      throw new Error("Invalid target");
  }
};

const App: React.FC<Props> = ({
  target,
  parameterPrefix = null,
  subseason = null,
  preselectedValues,
  authEndpoint,
  externalHotelEndpoint,
}) => {
  const isMounted = useRef<boolean>(false);
  const [wrappedApiInstance, setWrappedApiInstance] =
    useState<Wrap<KyInstance>>();

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;

      setWrappedApiInstance(
        wrapPromise<KyInstance>(ensureApiInstance(authEndpoint)),
      );
    }

    return () => {
      isMounted.current = false;
    };
  }, [authEndpoint]);

  if (!wrappedApiInstance) return <Loading />;

  const apiInstance = wrappedApiInstance.read();

  return (
    // <React.StrictMode>
    <DeviceTypeProvider>
      <WidgetProvider
        apiInstance={apiInstance}
        prefix={parameterPrefix}
        preselectedValues={preselectedValues}
        externalHotelEndpoint={externalHotelEndpoint}
        subseason={subseason}
      >
        <Container>
          <SearchMasterDataProvider>
            <WordpressHotelDataProvider>
              {renderContent(target)}
            </WordpressHotelDataProvider>
          </SearchMasterDataProvider>
        </Container>
      </WidgetProvider>
    </DeviceTypeProvider>
    // </React.StrictMode>
  );
};

export default App;
